<div class="container-fluid">
  <div class="col image-col">
    <img
      src="https://media.allergan.com/allerganaesthetics/globallanding/foa_cover.png"
    />
  </div>
  <div class="col content-col">
    <h1>Introducing the Future of Aesthetics Global Trends Report</h1>
    <div class="content-body">
      <p>
        Built on robust market research from a world-class forecasting think
        tank, crafted through social listening data, industry research, academic
        studies and Allergan Aesthetics proprietary global market research into
        consumer attitudes, and validated by interviews with leading aesthetics
        practitioners.<sup>1</sup>
      </p>
      <br />
      <p>
        The future of aesthetics is here. Get a unique view of what tomorrow
        holds for aesthetics to enable evolution for the future.
      </p>
      <br />
      <p>
        <a
          href="https://media.allergan.com/actavis/actavis/media/allergan-pdf-documents/Allergan%20Aesthetics%20Trends%20Report%20FINAL.pdf"
          target="_blank"
        >
          Download a copy of our global report.
        </a>
      </p>
      <p>
        <a
          href="https://www.allerganaesthetics.com/content/dam/aa-global/media/docs/AGNA-7238-Future-of-Aesthetics-Report-FINAL.pdf"
          target="_blank"
        >
          Download a copy of our US report (intended for US audiences).
        </a>
      </p>
    </div>
  </div>
</div>
